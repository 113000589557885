import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';

const EditPartner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    const fetchPartnerDetails = async () => {
      const db = getDatabase();
      const partnerRef = ref(db, `PartnerInfo/${id}`);
      const snapshot = await get(partnerRef);
      if (snapshot.exists()) {
        setPartner(snapshot.val());
      }
    };

    fetchPartnerDetails();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPartner((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    const db = getDatabase();
    const partnerRef = ref(db, `PartnerInfo/${id}`);
    await update(partnerRef, partner);
    navigate('/admin-dashboard/partner'); // Navigate to Admin Dashboard after updating
  };

  if (!partner) return <p>Loading...</p>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Edit Partner</h1>
      <div className="grid grid-cols-2 gap-4">
        <input
          type="text"
          name="firstName"
          value={partner.firstName || ''}
          onChange={handleInputChange}
          className="border px-4 py-2"
          placeholder="First Name"
        />
        <input
          type="text"
          name="lastName"
          value={partner.lastName || ''}
          onChange={handleInputChange}
          className="border px-4 py-2"
          placeholder="Last Name"
        />
        <input
          type="text"
          name="mobileNumber"
          value={partner.mobileNumber || ''}
          onChange={handleInputChange}
          className="border px-4 py-2"
          placeholder="Mobile Number"
        />
         <input
          type="text"
          name="nidaanId"
          value={partner.nidaanId || ''}
          onChange={handleInputChange}
          className="border px-4 py-2 bg-slate-500"
          placeholder="Nidaan Id"
          readOnly
        />
        {/* Dropdown for status */}
        <select
          name="status"
          value={partner.status || 'Status'} // Default value is "Review"
          onChange={handleInputChange}
          className="border px-4 py-2"
        >
          <option value="Live">Live</option>
          <option value="Review">Review</option>
        </select>
      </div>
      <button
        onClick={handleUpdate}
        className="bg-blue-500 text-white px-4 py-2 mt-4"
      >
        Update
      </button>
    </div>
  );
};

export default EditPartner;
