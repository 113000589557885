import React from 'react'

const DoctorsDatabase = () => {
  return (
    <div>
      doc
    </div>
  )
}

export default DoctorsDatabase
