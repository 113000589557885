import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ progress, height, color, backgroundColor }) => {
  return (
    <div
      className="progress-bar-container"
      style={{
        width: '100%',
        backgroundColor: backgroundColor || '#e0e0e0',
        borderRadius: '8px',
        overflow: 'hidden',
        height: height || '10px',
      }}
    >
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          backgroundColor: color || '#4caf50',
          height: '100%',
          transition: 'width 0.3s ease',
        }}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired, // Progress value between 0 and 100
  height: PropTypes.string, // Height of the progress bar
  color: PropTypes.string, // Color of the progress bar
  backgroundColor: PropTypes.string, // Background color of the container
};

export default ProgressBar;
