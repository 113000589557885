import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';

const EditHospital = () => {
  const { hospitalId } = useParams();
  const navigate = useNavigate();
  const [hospitalData, setHospitalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ayushmanData, setAyushmanData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);

  useEffect(() => {
    const fetchHospital = async () => {
      try {
        const docRef = doc(db, 'Hospital', hospitalId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setHospitalData({ id: hospitalId, ...docSnap.data() });
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching hospital data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchAyushmanData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'AyushmanCode'));
        const data = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAyushmanData(data);
      } catch (error) {
        console.error('Error fetching AyushmanCode data:', error);
      }
    };

    fetchHospital();
    fetchAyushmanData();
  }, [hospitalId]);

  const handleInputChange = (field, value) => {
    setHospitalData((prev) => ({ ...prev, [field]: value }));
  };

  const handleUpdate = async () => {
    try {
      const docRef = doc(db, 'Hospital', hospitalId);
      await updateDoc(docRef, hospitalData);
      alert('Hospital updated successfully!');
      navigate('/admin-dashboard/hospital-admin');
    } catch (error) {
      console.error('Error updating hospital:', error);
    }
  };

  const handleCheckboxChange = (speciality) => {
    setSelectedSpecialties((prev) => {
      if (prev.includes(speciality)) {
        return prev.filter(item => item !== speciality);
      } else {
        return [...prev, speciality];
      }
    });
  };

  const handleConfirm = () => {
    const specialties = selectedSpecialties.join(', ');
    handleInputChange('ayushmanCode', specialties);
    setShowModal(false);
  };

  const toggleModal = () => setShowModal(!showModal);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Edit Hospital</h1>
      {hospitalData && (
        <form className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium">Hospital Name</label>
            <input
              type="text"
              value={hospitalData.hospitalname || ''}
              onChange={(e) => handleInputChange('hospitalname', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Address</label>
            <input
              type="text"
              value={hospitalData.hospitaladdress || ''}
              onChange={(e) => handleInputChange('hospitaladdress', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">City</label>
            <input
              type="text"
              value={hospitalData.hospitalcity || ''}
              onChange={(e) => handleInputChange('hospitalcity', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Contact Number</label>
            <input
              type="text"
              value={hospitalData.hospitalnumber || ''}
              onChange={(e) => handleInputChange('hospitalnumber', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Ayushman Enabled</label>
            <select
              value={hospitalData.hospitalayushman || 'No'}
              onChange={(e) => handleInputChange('hospitalayushman', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium">Ayushman Service Code</label>
            <input
              type="text"
              value={hospitalData.ayushmanCode || ''}
              onChange={(e) => handleInputChange('ayushmanCode', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
              onClick={toggleModal} // Open the modal on click
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Services</label>
            <input
              type="text"
              value={hospitalData.hospitalservices || ''}
              onChange={(e) => handleInputChange('hospitalservices', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Logo URL</label>
            <input
              type="text"
              value={hospitalData.hospitallogo || ''}
              onChange={(e) => handleInputChange('hospitallogo', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Image URL</label>
            <input
              type="text"
              value={hospitalData.hospitalimage || ''}
              onChange={(e) => handleInputChange('hospitalimage', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium">Location</label>
            <input
              type="text"
              value={hospitalData.location || ''}
              onChange={(e) => handleInputChange('location', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </div>

          <div>
            <label className="block text-sm font-medium">Status</label>
            <select
              value={hospitalData.Status || 'Review'}
              onChange={(e) => handleInputChange('Status', e.target.value)}
              className="p-2 border border-gray-300 rounded w-full"
            >
              <option value="Review">Review</option>
              <option value="Hold">Hold</option>
              <option value="Live">Live</option>
            </select>
          </div>

          <div className="col-span-2">
            <button
              type="button"
              onClick={handleUpdate}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Update Hospital
            </button>
          </div>
        </form>
      )}

      {/* Modal for selecting specialties */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded w-96">
            <h3 className="text-xl font-semibold mb-4">Select Specialties</h3>
            <div className="overflow-y-auto max-h-72">
              <table className="table-auto w-full border-collapse border border-gray-200">
                <thead>
                  <tr>
                    <th className="border-b border-gray-300 px-4 py-2">Speciality</th>
                    <th className="border-b border-gray-300 px-4 py-2">Speciality code</th>
                    <th className="border-b border-gray-300 px-4 py-2">Select</th>
                  </tr>
                </thead>
                <tbody>
                  {ayushmanData.map((data) => (
                    <tr key={data.id}>
                      <td className="border-b border-gray-200 px-4 py-2">{data.speciality}</td>
                      <td className="border-b border-gray-200 px-4 py-2">{data.ayushmanCode}</td>
                      <td className="border-b border-gray-200 px-4 py-2 text-center">
                        <input
                          type="checkbox"
                          checked={selectedSpecialties.includes(data.speciality)}
                          onChange={() => handleCheckboxChange(data.speciality)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleConfirm}
                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
              >
                Confirm
              </button>
              <button
                onClick={toggleModal}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditHospital;
