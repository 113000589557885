import React, { useState } from 'react';
import { db } from '../config/firebase'; // Ensure Firebase is configured properly
import { collection, addDoc } from 'firebase/firestore';

const AddHospital = () => {
  const [formData, setFormData] = useState({
    hospitalname: '',
    hospitaladdress: '',
    hospitalcity: '',
    hospitalnumber: '',
    hospitalayushman: '',
    ayushmanCode: '',
    hospitalservices: '',
    hospitallogo: '',
    hospitalimage: '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await addDoc(collection(db, 'Hospital'), formData);
      alert('Hospital added successfully!');
      setFormData({
        hospitalname: '',
        hospitaladdress: '',
        hospitalcity: '',
        hospitalnumber: '',
        hospitalayushman: '',
        ayushmanCode: '',
        hospitalservices: '',
        hospitallogo: '',
        hospitalimage: '',
      });
    } catch (error) {
      console.error('Error adding hospital:', error);
      alert('Failed to add hospital. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Add Hospital</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 font-medium">Hospital Name</label>
          <input
            type="text"
            name="hospitalname"
            value={formData.hospitalname}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Address</label>
          <input
            type="text"
            name="hospitaladdress"
            value={formData.hospitaladdress}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">City</label>
          <input
            type="text"
            name="hospitalcity"
            value={formData.hospitalcity}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Contact Number</label>
          <input
            type="text"
            name="hospitalnumber"
            value={formData.hospitalnumber}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Ayushman Availability</label>
          <select
            name="hospitalayushman"
            value={formData.hospitalayushman}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div>
          <label className="block mb-1 font-medium">Ayushman Code</label>
          <input
            type="text"
            name="ayushmanCode"
            value={formData.ayushmanCode}
            onChange={handleChange}
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Services</label>
          <textarea
            name="hospitalservices"
            value={formData.hospitalservices}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Logo URL</label>
          <input
            type="url"
            name="hospitallogo"
            value={formData.hospitallogo}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <div>
          <label className="block mb-1 font-medium">Image URL</label>
          <input
            type="url"
            name="hospitalimage"
            value={formData.hospitalimage}
            onChange={handleChange}
            required
            className="p-2 border border-gray-300 rounded w-full"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`px-4 py-2 text-white rounded ${
            loading ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-600'
          }`}
        >
          {loading ? 'Adding...' : 'Add Hospital'}
        </button>
      </form>
    </div>
  );
};

export default AddHospital;
