import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth'; // Import Firebase auth methods

const Footer = () => {
  const navigate = useNavigate();
  const auth = getAuth(); // Get the current auth instance

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Redirect to the dashboard after successful logout
        navigate('/admin-dashboard');
      })
      .catch((error) => {
        console.error('Error logging out:', error.message);
      });
  };

  const handleOpenAnalyticalHome = () => {
    // Open "analytical-home" in a new window
    window.open('/analytical-home', '_blank');
  };

  return (
    <footer className="bg-blue-900 text-white py-10">
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-8">
        {/* Section 1: Nidaan */}
        <div>
          <h3 className="font-semibold mb-4">Nidaan</h3>
          <ul>
            <li className="mb-2">
              {/* Logout and redirect to dashboard */}
              <button
                onClick={handleLogout}
                className="hover:text-gray-300 focus:outline-none"
              >
                Admin
              </button>
            </li>
          </ul>
        </div>
        {/* Section 2: Nidaan Analytical */}
        <div>
          <h3 className="font-semibold mb-4">Nidaan Analytical</h3>
          <ul>
            <li className="mb-2">
              {/* Open analytical home in a new window */}
              <button
                onClick={handleOpenAnalyticalHome}
                className="hover:text-gray-300 focus:outline-none"
              >
                Nidaan Analytical Home
              </button>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
