import React, { useEffect, useState } from 'react';
import { Link, useNavigate, Outlet } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import { FaBars, FaHome, FaUsers, FaAddressCard, FaUserInjured } from 'react-icons/fa';
import { RiHospitalFill } from "react-icons/ri";
import { FaUserDoctor } from "react-icons/fa6";
import { FcMoneyTransfer } from "react-icons/fc";
import { IoIosArrowDropdownCircle } from "react-icons/io";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [userFirstName, setUserFirstName] = useState(null);
  const [userRole, setUserRole] = useState(null);  // State to store user role
  const [loading, setLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false); // State for collapsing sidebar
  const [showUserSubLinks, setShowUserSubLinks] = useState(false);
  const [showAccountSubLinks, setShowAccountSubLinks] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const db = getDatabase();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const adminRef = ref(db, `AdminInfo/${user.uid}`);

        get(adminRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              setUserFirstName(snapshot.val().firstName);
              setUserRole(snapshot.val().role);  // Fetch and set the role from the database
            } else {
              console.error("No data found for this UID in AdminInfo.");
            }
          })
          .catch((error) => {
            console.error("Error fetching data from Firebase:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        navigate('/admin-login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className='min-h-[calc(100vh-120px)] flex'>
      {/* Sidebar */}
      <aside className={`bg-white dark:bg-gray-900 ${isCollapsed ? 'w-16' : 'w-60'} transition-all duration-300 min-h-full shadow-md`}>
        <div className='flex items-center justify-between p-4'>
          <span className={`${isCollapsed ? 'hidden' : 'block'} text-xl font-bold text-blue-500`}>Admin Dashboard </span>
          <button onClick={() => setIsCollapsed(!isCollapsed)}>
            <FaBars className='text-xl text-blue-500' />
          </button>
        </div>
        <div>
          {userFirstName ? (
            <div>
              <p>Welcome, {userFirstName} ({userRole})</p> {/* Displaying role along with first name */}
            </div>
          ) : (
            <p>No user data available.</p>
          )}
        </div>

        <nav className='mt-10'>
          <Link to="" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaHome className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Home</span>
          </Link>

          <Link to="patient-admin" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserInjured className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Patient</span>
          </Link>

          <Link to="health-card-old" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserInjured className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Old H C</span>
          </Link>

          <div className='relative'>
            <button
              onClick={() => setShowUserSubLinks(!showUserSubLinks)}
              className='flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-blue-500 hover:text-white'
            >
              <FaUsers className='text-xl' />
              <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Users</span>
              <IoIosArrowDropdownCircle className='text-xl' />
            </button>
            {showUserSubLinks && (
              <div className={`mt-2 space-y-2 ${isCollapsed ? 'hidden' : 'block'}`}>
                <Link to="management" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  Management
                </Link>

                <Link to="partner" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  Partner
                </Link>

                <Link to="users" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                  Users
                </Link>

              </div>
            )}
          </div>

          <Link to="doctors-admin" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserDoctor className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Doctors</span>
          </Link>
          <Link to="doctors-database" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaUserDoctor className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Doctors Database</span>
          </Link>

          <Link to="hospital-admin" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <RiHospitalFill className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Hospital</span>
          </Link>

          <Link to="ayushman-code" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <RiHospitalFill className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Ayushman</span>
          </Link>

          <Link to="health-card-admin" className='flex items-center space-x-2 px-4 py-2 hover:bg-blue-500 hover:text-white'>
            <FaAddressCard className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Health Card</span>
          </Link>

          <button
            onClick={() => setShowAccountSubLinks(!showAccountSubLinks)}
            className='flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-blue-500 hover:text-white'
          >
            <FcMoneyTransfer className='text-xl' />
            <span className={`${isCollapsed ? 'hidden' : 'block'}`}>Accounts</span>
            <IoIosArrowDropdownCircle className='text-xl' />
          </button>

          {showAccountSubLinks && (
            <div className={`mt-2 space-y-2 ${isCollapsed ? 'hidden' : 'block'}`}>
              <Link to="" className='flex items-center space-x-2 px-8 py-2 hover:bg-blue-500 hover:text-white'>
                Referral
              </Link>
            </div>
          )}

        </nav>
      </aside>

      {/* Right Side Content */}
      <main className='flex-grow p-4 bg-gray-100'>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminDashboard;
