import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import SignUp from '../pages/SignUp';
import Hospital from '../pages/Hospital';
import ForDoctors from '../pages/ForDoctors';
import HospitalDetails from '../pages/HospitalDetails';
import AdminDashboard from '../../src/admin-area/AdminDashboard';
import AdminLogin from '../admin-area/AdminLogin';
import AdminSignUp from '../admin-area/AdminSignUp';
import ErrorPage from "../components/ErrorPage"; // Import the ErrorPage component
import AdminHome from '../admin-area/AdminHome';
import Test from '../admin-area/Test';
import Management from '../admin-area/Management';
import Partner from '../admin-area/Partner';
import Users from '../admin-area/Users';
import HospitalAdmin from '../admin-area/HospitalAdmin';
import DoctorsAdmin from '../admin-area/DoctorsAdmin';
import HealthCardAdmin from '../admin-area/HealthCardAdmin';
import PatientAdmin from '../admin-area/PatientAdmin';
import HealthCard from '../pages/HealthCard';
import EditHospital from '../admin-area/EditHospital';
import AyushmanCode from '../admin-area/AyushmanCode';
import AddHospital from '../admin-area/AddHospital';
import DoctorsDatabase from '../admin-area/DoctorsDatabase';
import NoAuthorization from '../admin-area/admincomponents/NoAuthorization';
import EditPatient from '../admin-area/EditPatient';
import EditAdmin from '../admin-area/EditAdmin';
import OldHealthCard from '../admin-area/OldHealthCard';
import AnalyticalHome from '../analytical/AnalyticalHome';
import EditPartner from '../admin-area/EditPartner ';
import HcCalander from '../admin-area/HcCalander';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: "login",
        element: <Login />
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />
      },
      {
        path: "sign-up",
        element: <SignUp />
      },
      {
        path: "hospital",
        element: <Hospital />
      },
      {
        path: "for-doctors",
        element: <ForDoctors />
      },
      {
        path: "hospital-details/:hospitalId",
        element: <HospitalDetails />
      },
      {
        path: "health-card",
        element: <HealthCard />
      },

      {
        path: "analytical-home",
        element: <AnalyticalHome />
      },

      {
        path: "admin-dashboard",
        element: <AdminDashboard />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "",
            element: <AdminHome />
          },
          {
            path: "test",
            element: <Test />
          },
          {
            path: "management",
            element: <Management />
          },
          {
            path: "partner",
            element: <Partner />
          },
          {
            path: "users",
            element: <Users />
          },
          {
            path: "hospital-admin",
            element: <HospitalAdmin />
          },
          {
            path: "doctors-admin",
            element: <DoctorsAdmin />
          },
          {
            path: "doctors-database",
            element: <DoctorsDatabase />
          },
          {
            path: "health-card-admin",
            element: <HealthCardAdmin />
          },
          {
            path: "health-card-old",
            element: <OldHealthCard />
          },
          {
            path: "not-authorized",
            element: <NoAuthorization/>
          },
          {
            path: "hc-calander",
            element: <HcCalander/>
          },
          {
            path: "patient-admin",
            element: <PatientAdmin />
          },
          {
            path: 'edit-hospital/:hospitalId',
            element: <EditHospital />
          },
          {
            path: 'edit-admin/:adminId',
            element: <EditAdmin />
          },
          {
            path: 'edit-partner/:id',
            element: <EditPartner />
          },
          
          {
            path: 'edit-patient/:id',
            element: <EditPatient />
          },
         
          {
            path: 'ayushman-code',
            element: <AyushmanCode />
          },
          {
            path: 'add-hospital',
            element: <AddHospital />
          }
        ]
      },
      {
        path: "admin-login",
        element: <AdminLogin />
      },
      {
        path: "admin-sign-up",
        element: <AdminSignUp />
      }
    ]
  }
]);

export default router;
