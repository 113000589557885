import React, { useState } from 'react';
import { FaMedkit, FaUser } from 'react-icons/fa';
import { FaClipboardCheck, FaHospital, FaNotesMedical, FaPhone, FaStethoscope } from 'react-icons/fa6';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { app } from '../config/firebase'; // Adjust the import according to your file structure

const Surgery = () => {
  const [name, setName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [problem, setProblem] = useState('');
  const [city, setCity] = useState('');

  const db = getFirestore(app);
  const auth = getAuth(app);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;
      const userId = user ? user.uid : 'anonymous';

      await addDoc(collection(db, 'appointments'), {
        name,
        contactNumber,
        problem,
        city,
        userId,
        timestamp: serverTimestamp(),
      });

      alert('Appointment booked successfully!');
      // Clear the form fields after submission
      setName('');
      setContactNumber('');
      setProblem('');
      setCity('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Failed to book appointment. Please try again.');
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-4xl font-bold text-center mb-8">Nidaan Surgeries - How It Works</h1>
      <div className="mb-8 flex flex-col lg:flex-row">
        <div className="lg:w-2/3">
          <ol className="list-decimal list-inside mt-4 space-y-4">
            <li className="flex items-start space-x-4">
              <FaUser className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Connect with us</h2>
                <p>A dedicated Nidaan Care Assistant will guide you at every step.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FaStethoscope className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Schedule a consultation</h2>
                <p>Schedule a consultation with an expert Nidaan Care Doctor near you.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FaNotesMedical className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Get Diagnosis & Treatment</h2>
                <p>Get Diagnosis & Treatment advice from our expert doctors.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FaClipboardCheck className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Procedure or Surgery</h2>
                <p>In some cases, a procedure or a surgery might be recommended.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FaHospital className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Choose a Hospital</h2>
                <p>Choose one of our partner hospitals that meets your requirements.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FaMedkit className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Complete Assistance</h2>
                <p>Get complete assistance from Admission to Discharge: Travel, Insurance Paperwork, Admission Procedure, Discharge Formalities, Pre-surgery tests.</p>
              </div>
            </li>
            <li className="flex items-start space-x-4">
              <FaPhone className="text-xl mt-1" />
              <div>
                <h2 className="font-semibold">Post-Operative Support</h2>
                <p>Receive Post-Operative support and Follow-up Consultations.</p>
              </div>
            </li>
          </ol>
        </div>

        <div className="lg:w-1/3 lg:ml-8 mt-8 lg:mt-0">
          <form className="bg-white p-8 shadow-md rounded-lg" onSubmit={handleSubmit}>
            <h2 className="text-2xl font-semibold mb-6">Book a Consultation</h2>

            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="mt-1 p-2 border rounded w-full"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">City</label>
              <input
                type="text"
                className="mt-1 p-2 border rounded w-full"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Contact Number</label>
              <input
                type="tel"
                className="mt-1 p-2 border rounded w-full"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Problem</label>
              <input
                type="text"
                className="mt-1 p-2 border rounded w-full"
                value={problem}
                onChange={(e) => setProblem(e.target.value)}
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Book Appointment
            </button>

            <div className="flex flex-col items-center gap-6 p-6">
              <p>------------------------------</p>
                    <p className="text-xl">or</p>
              <p>Reach out with us on +91 9302289924</p>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default Surgery;
