import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import ProgressBar from '../components/ProgressBar'; // Import ProgressBar component

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Hospital = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [progress, setProgress] = useState(0); // State to track progress
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      const fetchHospitals = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'Hospital'));
          const totalHospitals = querySnapshot.docs.length; // Get the total number of hospitals
          let fetchedHospitals = [];
          
          // Loop through each document and simulate progress
          for (let i = 0; i < totalHospitals; i++) {
            const doc = querySnapshot.docs[i];
            const hospitalData = doc.data();
  
            // Only include hospitals with status 'Live'
            if (hospitalData.Status === 'Live') {
              fetchedHospitals.push({
                id: doc.id,
                ...hospitalData,
              });
            }
  
            // Update progress after each item is fetched
            setProgress(Math.floor(((i + 1) / totalHospitals) * 100));
  
            // Simulate a small delay (optional)
            await new Promise(resolve => setTimeout(resolve, 100)); // Delay for better progress visualization
          }
  
          // Once all data is fetched, shuffle and update the state
          setHospitals(shuffleArray(fetchedHospitals));
        } catch (error) {
          console.error('Error fetching hospital data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchHospitals();
    }
  }, [isOnline]);
  

  const handleCardClick = (hospitalId) => {
    navigate(`/hospital-details/${hospitalId}`); // Navigate to the dynamic route
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">
        {isOnline ? 'Nidaan Partner Hospital' : 'No Connection Available, Please Connect to Internet'}
      </h2>

      {/* Show progress bar while loading */}
      {loading && isOnline ? (
        <div className="mb-4">
          <ProgressBar progress={progress} height="10px" color="#4caf50" />
        </div>
      ) : (
        isOnline && (
          <div className="flex overflow-x-auto space-x-4 scrollbar-hide py-2">
            {hospitals.map((hospital) => (
              <div
                key={hospital.id}
                onClick={() => handleCardClick(hospital.id)} // Handle card click
                className="min-w-[200px] sm:min-w-[250px] bg-white rounded-lg shadow-md border border-gray-200 flex-shrink-0 transition-transform transform hover:scale-105 cursor-pointer"
              >
                <img
                  src={hospital.hospitalimage}
                  alt={hospital.hospitalname}
                  className="h-36 w-full object-cover rounded-t-lg"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold text-gray-800 truncate">
                    {hospital.hospitalname}
                  </h3>
                  <p className="text-sm text-gray-600">{hospital.hospitalcity}</p>
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default Hospital;
