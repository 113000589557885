import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, update } from 'firebase/database';
import { app } from '../config/firebase';

const EditAdmin = () => {
  const { adminId } = useParams();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const db = getDatabase(app);
    const adminRef = ref(db, `AdminInfo/${adminId}`);

    // Fetch the specific admin data
    get(adminRef).then((snapshot) => {
      if (snapshot.exists()) {
        setAdminData(snapshot.val());
      }
      setLoading(false);
    });
  }, [adminId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminData({ ...adminData, [name]: value });
  };

  const handleUpdate = () => {
    const db = getDatabase(app);
    const adminRef = ref(db, `AdminInfo/${adminId}`);

    // Update data in Firebase
    update(adminRef, adminData)
      .then(() => {
        alert('Admin info updated successfully!');
        navigate('/admin-dashboard/management');
      })
      .catch((error) => {
        console.error('Error updating admin info:', error);
      });
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen text-xl">Loading...</div>;
  }

  return (
    <div className="container mx-auto p-6 max-w-lg bg-white shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">Edit Admin</h1>
      <div className="space-y-4">
        <input
          type="text"
          name="firstName"
          value={adminData.firstName || ''}
          onChange={handleInputChange}
          placeholder="First Name"
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
        <input
          type="text"
          name="lastName"
          value={adminData.lastName || ''}
          onChange={handleInputChange}
          placeholder="Last Name"
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
        <input
          type="email"
          name="email"
          value={adminData.email || ''}
          onChange={handleInputChange}
          placeholder="Email"
          className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
        <div className="space-y-2">
          <label className="block text-gray-600">Status</label>
          <select
            name="status"
            value={adminData.status || 'Live'}
            onChange={handleInputChange}
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option value="Live">Live</option>
            <option value="Hold">Hold</option>
            <option value="In Review">In Review</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="block text-gray-600">Role</label>
          <select
            name="role"
            value={adminData.role || 'Admin'}
            onChange={handleInputChange}
            className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option value="Admin">Admin</option>
            <option value="Manager">Manager</option>
            <option value="super-admin">super-admin</option>
          </select>
        </div>
        <button
          onClick={handleUpdate}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 rounded-lg transition duration-200"
        >
          Update Admin
        </button>
      </div>
    </div>
  );
};

export default EditAdmin;
