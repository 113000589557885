import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

const EditPatient = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [zoomLevel, setZoomLevel] = useState(1);

  // Fetch patient data
  useEffect(() => {
    const fetchPatientData = async () => {
      try {
        const docRef = doc(db, 'NidaanPatient', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPatientData(docSnap.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching patient data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPatientData();
  }, [id]);

  // Fetch hospitals for dropdown
  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Hospital'));
        const hospitalList = querySnapshot.docs.map((doc) => doc.data().hospitalname);
        setHospitals(hospitalList);
      } catch (error) {
        console.error('Error fetching hospitals:', error);
      }
    };

    fetchHospitals();
  }, []);

  // Handle update submission
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, 'NidaanPatient', id);
      await updateDoc(docRef, patientData);
      alert('Patient updated successfully!');
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error('Error updating patient:', error);
    }
  };

  // Open the image in a modal
  const openImageModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsImageModalOpen(true);
  };

  // Close the image modal
  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setZoomLevel(1);
  };

  // Zoom in the image
  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom * 1.1);
  };

  // Zoom out the image
  const zoomOut = () => {
    setZoomLevel((prevZoom) => prevZoom / 1.1);
  };

  if (loading) return <div className="text-center text-gray-500">Loading...</div>;

  return (
    <div className="max-w-6xl mx-auto mt-10 p-8 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Edit Patient Details</h2>
      
      <form onSubmit={handleUpdate} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Read-only Field for Patient ID */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Patient ID (NidaanPtId):</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md p-2 bg-gray-100 text-gray-700 cursor-not-allowed"
              value={patientData.NidaanPtId || ''}
              readOnly
            />
          </div>

          {/* Read-only Field for Partner ID */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Partner ID:</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md p-2 bg-gray-100 text-gray-700 cursor-not-allowed"
              value={patientData.partnerId || ''}
              readOnly
            />
          </div>

          {/* Editable Fields */}
          {[{ label: 'Name', key: 'name' }, { label: 'Number', key: 'number' }, { label: 'Referred By', key: 'refBy' }, { label: 'Referral Number', key: 'refNumber' }, { label: 'Admit Date', key: 'admit', type: 'date' }, { label: 'Discharge Date', key: 'discharge', type: 'date' }, { label: 'Timestamp', key: 'timestamp' }].map(({ label, key, type = 'text' }) => (
            <div key={key}>
              <label className="block text-sm font-medium text-gray-700 mb-2">{label}:</label>
              <input
                type={type}
                className="w-full border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
                value={patientData[key] || ''}
                onChange={(e) =>
                  setPatientData({
                    ...patientData,
                    [key]: e.target.value,
                  })
                }
              />
            </div>
          ))}

          {/* Hospital Field */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Hospital:</label>
            <select
              className="w-full border border-gray-300 rounded-md p-2 focus:ring-blue-500 focus:border-blue-500"
              value={patientData.hospital || ''}
              onChange={(e) =>
                setPatientData({
                  ...patientData,
                  hospital: e.target.value,
                })
              }
            >
              <option value="">Select Hospital</option>
              {hospitals.map((hospital, index) => (
                <option key={index} value={hospital}>
                  {hospital}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Or enter manually"
              className="w-full border border-gray-300 rounded-md p-2 mt-2 focus:ring-blue-500 focus:border-blue-500"
              value={patientData.hospital || ''}
              onChange={(e) =>
                setPatientData({
                  ...patientData,
                  hospital: e.target.value,
                })
              }
            />
          </div>

          {/* Boolean Fields */}
          {[{ label: 'Aadhar', key: 'aadhar' }, { label: 'Ayushman', key: 'ayushman' }, { label: 'Samagra', key: 'samagra' }].map(({ label, key }) => (
            <div key={key}>
              <label className="block text-sm font-medium text-gray-700 mb-2">{label}:</label>
              <input
                type="checkbox"
                className="w-5 h-5 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                checked={!!patientData[key]}
                onChange={(e) =>
                  setPatientData({
                    ...patientData,
                    [key]: e.target.checked,
                  })
                }
              />
            </div>
          ))}
        </div>

        {/* Display Images */}
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Uploaded Reports:</h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {patientData.reportLinks &&
              Object.entries(patientData.reportLinks).map(([key, url]) => (
                <div key={key} className="border border-gray-200 p-2 rounded-md">
                  <img
                    src={url}
                    alt={`Report for ${key}`} // More descriptive alt text
                    className="w-full h-32 object-cover rounded-md mb-2 cursor-pointer"
                    onClick={() => openImageModal(url)}
                  />
                  <p className="text-sm text-gray-600">{key}</p>
                </div>
              ))}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Update
          </button>
        </div>
        <div>
          <button className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
            Report Analysis
          </button>
        </div>
        <text>
          lakfjlakJ AKLF J;ALkd j;lakjsd ;lakj d;lkaj dl
        </text>
      </form>

      {/* Modal for Image */}
      {isImageModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="relative bg-white max-w-lg max-h-[80vh] overflow-auto p-4 rounded-lg">
            <img
              src={currentImage}
              alt="Enlarged Report"
              style={{ transform: `scale(${zoomLevel})` }}
              className="w-full h-auto"
            />
            <button
              onClick={zoomIn}
              className="absolute top-4 left-4 text-white bg-blue-500 p-2 rounded-full"
            >
              Zoom In
            </button>

            <button
              onClick={zoomOut}
              className="absolute top-4 left-32 text-white bg-blue-500 p-2 rounded-full"
            >
              Zoom Out
            </button>

            <button
              onClick={closeImageModal}
              className="absolute top-4 right-16 text-white bg-red-500 p-2 rounded-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditPatient;
