import React, { useState } from 'react';
import Logo from '../../components/Logo';
import { Link } from 'react-router-dom';
import { GrSearch } from 'react-icons/gr';
import { FaPhoneAlt } from "react-icons/fa";

const AnalyticalHeader = () => {
  const [showSubMenu, setShowSubMenu] = useState(false); // State to control Products submenu visibility
  const [showSubMenuGas, setShowSubMenuGas] = useState(false); // State to control Gas Equipment submenu visibility
  const [showSubMenuUv, setShowSubMenuUv] = useState(false); // State to control Gas Equipment submenu visibility

  const handleToggleSubMenu = () => {
    setShowSubMenu(!showSubMenu); // Toggle Products submenu visibility
  };

  const handleToggleSubMenuGas = () => {
    setShowSubMenuGas(!showSubMenuGas); // Toggle Gas Equipment submenu visibility
  };
  const handleToggleSubMenuUv = () => {
    setShowSubMenuUv(!showSubMenuUv); // Toggle Gas Equipment submenu visibility
  };

  return (
    <header className='h-full shadow-md bg-white'>
      {/* Top bar */}
      <div className='bg-red-500 px-5 py-2'>
        <div className='flex items-center gap-2 text-white'>
          <FaPhoneAlt />
          <span>+91 7999788172</span>
        </div>
      </div>

      {/* Header */}
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        {/* Logo */}
        <div>
          <Link to='/'>
            <Logo w={90} h={60} />
          </Link>
        </div>

        {/* Search Box */}
        <div className='hidden lg:flex items-center w-full justify-between max-w-sm border rounded-full focus-within:shadow-md pl-2 relative'>
          <input
            type='text'
            placeholder='Search product here ....'
            className='w-full outline-none'
          />
          <div className='text-lg min-w-[50px] h-8 bg-red-600 flex items-center justify-center rounded-r-full cursor-pointer text-white'>
            <GrSearch />
          </div>
        </div>

        {/* Links */}
        <div className='flex items-center gap-6 relative'>
          {/* Products with Dropdown */}
          <div className='relative'>
            {/* Parent Link */}
            <div
              className='cursor-pointer'
              onClick={handleToggleSubMenu} // Toggle Products submenu on click
            >
              Products
            </div>

            {/* Products Dropdown */}
            {showSubMenu && (
              <div className='absolute top-8 left-0 bg-white shadow-lg rounded-md p-4 z-10 w-72'>
                <ul className='flex flex-col gap-2'>
                  {/* Analytical with Submenu */}
                  <li className='relative'>
                    <div
                      className='cursor-pointer hover:text-red-500'
                      onClick={handleToggleSubMenuGas}
                    >
                      Analytical Instruments
                    </div>

                    {/* Analytical Submenu */}
                    {showSubMenuGas && (
                      <div className='absolute top-0 -left-72 bg-gray-100 rounded-md p-2 shadow-md w-64'>
                        <ul className='flex flex-col gap-2'>
                          <li>
                            <Link to='/equipment1' className='hover:text-red-500'>
                              HPLC
                            </Link>
                          </li>
                          <li>
                            <Link to='/equipment2' className='hover:text-red-500'>
                              GC
                            </Link>
                          </li>
                          
                        </ul>
                      </div>
                    )}
                  </li>

                   {/* Analytical with Submenu */}
                   <li className='relative'>
                    <div
                      className='cursor-pointer hover:text-red-500'
                      onClick={handleToggleSubMenuUv}
                    >
                     UV-Vis Spectrophotometer
                    </div>
                     {/* Analytical Submenu */}
                     {showSubMenuUv && (
                      <div className='absolute top-0 -left-72 bg-gray-100 rounded-md p-2 shadow-md w-64'>
                        <ul className='flex flex-col gap-2'>
                          <li>
                            <Link to='/equipment1' className='hover:text-red-500'>
                            Double Beam UV-VIS Spectrophotometer
                            </Link>
                          </li>
                          <li>
                            <Link to='/equipment2' className='hover:text-red-500'>
                            Single Beam UV-VIS Spectrophotometer​
                            </Link>
                          </li>
                          <li>
                            <Link to='/equipment2' className='hover:text-red-500'>
                            Digital Visible Spectrophotometer​
                            </Link>
                          </li>
                          <li>
                            <Link to='/equipment2' className='hover:text-red-500'>
                            FT-IR Spectrometer
                            </Link>
                          </li>
                          <li>
                            <Link to='/equipment2' className='hover:text-red-500'>
                            Visual colorimeter
                            </Link>
                          </li>
                          <li>
                            <Link to='/equipment2' className='hover:text-red-500'>
                            Atomic Absorption Spectrophotometer
                            </Link>
                          </li>
                          
                          
                        </ul>
                      </div>
                    )}
                  </li>


                 
                </ul>
              </div>
            )}
          </div>

          {/* Other Links */}
          <div>
            <Link to='/about' className='hover:text-red-500'>
              About Us
            </Link>
          </div>
          <div>
            <Link to='/contact' className='hover:text-red-500'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AnalyticalHeader;
