import React from 'react';
import authImg from '../../assets/forbidden.jpg'
import { useNavigate } from 'react-router-dom';


const NoAuthorization = () => {

  const navigate = useNavigate();


  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img
        src={authImg}
        alt="Forbidden"
        className="w-1/4 max-w-xs rounded-lg shadow-lg"
      />
      <h1 className="mt-6 text-2xl font-semibold text-gray-800">
        You are not authorized to view this page
      </h1>
      <p className="mt-2 text-gray-600">
        Please contact the administrator for access.
      </p>
      <button
        onClick={() => navigate('/admin-dashboard')}
        className="mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
      >
        Go Back
      </button>
    </div>
  );
};

export default NoAuthorization;
