import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Partner = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [healthCardCounts, setHealthCardCounts] = useState({}); // State to store health card counts

  useEffect(() => {
    const fetchPartnerInfo = async () => {
      const db = getDatabase(); // Initialize the Realtime Database instance
      const partnerRef = ref(db, 'PartnerInfo');
      onValue(partnerRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const partnerArray = Object.keys(data).map((key) => ({
            id: key, // Firebase unique ID
            ...data[key],
          }));

          // Fetch health card counts for each partner
          await fetchHealthCardCounts(partnerArray);
          setPartners(partnerArray);
        }
        setLoading(false);
      });
    };

    const fetchHealthCardCounts = async (partners) => {
      const firestore = getFirestore();
      const healthCardCountsTemp = {};

      for (const partner of partners) {
        if (partner.nidaanId) {
          const healthCardQuery = query(
            collection(firestore, 'NidaanHealthCard'),
            where('nidaanId', '==', partner.nidaanId)
          );
          const querySnapshot = await getDocs(healthCardQuery);

          // Store the count of health cards for the current partner
          healthCardCountsTemp[partner.nidaanId] = querySnapshot.size;
        }
      }

      setHealthCardCounts(healthCardCountsTemp);
    };

    fetchPartnerInfo();
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Partner Information</h1>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">First Name</th>
                <th className="border border-gray-300 px-4 py-2">Last Name</th>
                <th className="border border-gray-300 px-4 py-2">Mobile Number</th>
                <th className="border border-gray-300 px-4 py-2">Nidaan ID</th>
                <th className="border border-gray-300 px-4 py-2">Health Cards</th>
                <th className="border border-gray-300 px-4 py-2">Rating</th>
                <th className="border border-gray-300 px-4 py-2">Role</th>
                <th className="border border-gray-300 px-4 py-2">Status</th>
                <th className="border border-gray-300 px-4 py-2">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {partners.length > 0 ? (
                partners.map((partner) => (
                  <tr key={partner.id} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">
                      <Link
                        to={`/admin-dashboard/edit-partner/${partner.id}`}
                        className="text-blue-600 hover:underline"
                      >
                        {partner.firstName}
                      </Link>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">{partner.lastName}</td>
                    <td className="border border-gray-300 px-4 py-2">{partner.mobileNumber}</td>
                    <td className="border border-gray-300 px-4 py-2">{partner.nidaanId}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      {healthCardCounts[partner.nidaanId] || 0}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">{partner.rating}</td>
                    <td className="border border-gray-300 px-4 py-2">{partner.role}</td>
                    <td className="border border-gray-300 px-4 py-2">{partner.status}</td>
                    <td className="border border-gray-300 px-4 py-2">{partner.timeStamp}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center border border-gray-300 px-4 py-2">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Partner;
