import React from 'react'
import Mobile from '../assets/plus.png'
import LogoImage from '../assets/logoonefifty.png'
import { Link } from 'react-router-dom';


const ForDoctors = () => {
  return (
<div className="bg-gray-100">
      {/* Header Section */}
      <section className="bg-amber-300 text-white py-12">
        <div className="container mx-auto flex flex-col lg:flex-row items-center px-6">
          {/* Text Section */}
          <div className="lg:w-1/2">
          <p className='text-2xl'>
            For Doctors
          </p>
            <h2 className="text-3xl lg:text-4xl font-bold mb-4">
              Consult online and grow your practice
            </h2>
            <p className="mb-6">
              Start your own virtual practice with Nidaan Plus and consult patients over video, phone, and chat.
            </p>
            <Link to="/doctor-profile" className="bg-yellow-500 text-black font-semibold py-2 px-6 rounded hover:bg-yellow-600">
              Create Your Profile
            </Link>
          </div>

          {/* Image Section */}
          <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center lg:justify-end">
            <img
              src={Mobile} // Replace this URL with your actual image URL
              alt="App Screenshots"
              className="rounded-lg shadow-lg w-1/2 md:w-1/3 lg:w-1/4" // Adjust these classes to control the image size
            />
          </div>
        </div>
      </section>

      {/* Informative Section */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-6">
          <div className="flex flex-col lg:flex-row items-center">
            {/* Image Illustration */}
            <div className="lg:w-1/2 flex justify-center lg:justify-start">
              <img
                src={LogoImage} // Replace this URL with your actual illustration URL
                alt="Illustration"
                className="mb-6 lg:mb-0"
              />
            </div>

            {/* Text Section */}
            <div className="lg:w-1/2 lg:pl-8">
              <h3 className="text-2xl lg:text-3xl font-bold mb-4">
                Reach new patients at the comfort of their homes
              </h3>
              <ul className="text-gray-700 list-disc pl-5">
                <li className="mb-2">
                  Thousands of patients ask health queries on Nidaan. Get discovered by these patients by answering their questions within 24 hours.
                </li>
                <li className="mb-2">
                  Sign up for paid consultations and chat with patients using the NIdaan app - share images and documents with them and upload prescriptions to solve their medical problems.
                </li>
              </ul>
              <p className="mt-4">
                Download the <a href="nidaan.life" className="text-blue-500 font-semibold">Nidaan Doctor Plus</a> to get started.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


export default ForDoctors
