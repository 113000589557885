import React from 'react'
import Hospital from './Hospital'
import Surgery from './Surgery'
import ForDoctors from './ForDoctors'
import HealthCard from './HealthCard'
const Home = () => {
  return (
    <div>
      
      <Hospital/>
      
      <Surgery/>
      <HealthCard/>
      <ForDoctors/>
    </div>
  )
}

export default Home
