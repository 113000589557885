import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database"; // Import Firebase database functions

const Users = () => {
  const [users, setUsers] = useState([]); // State to store user data

  useEffect(() => {
    const db = getDatabase(); // Get the database instance
    const usersRef = ref(db, "UserInfo"); // Reference to the 'UserInfo' node in the database

    // Fetch data from the database
    const unsubscribe = onValue(usersRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const formattedData = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setUsers(formattedData); // Update state with the formatted data
      } else {
        setUsers([]); // Set empty array if no data exists
      }
    });

    return () => unsubscribe(); // Cleanup the subscription on unmount
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Users</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded border-collapse border border-gray-200">
          <thead>
            <tr>
              <th className="border border-gray-200 px-4 py-2 text-left font-medium text-gray-600">
                First Name
              </th>
              <th className="border border-gray-200 px-4 py-2 text-left font-medium text-gray-600">
                Last Name
              </th>
              <th className="border border-gray-200 px-4 py-2 text-left font-medium text-gray-600">
                Phone Number
              </th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <tr key={user.id} className="hover:bg-gray-100">
                  <td className="border border-gray-200 px-4 py-2">{user.firstName}</td>
                  <td className="border border-gray-200 px-4 py-2">{user.lastName}</td>
                  <td className="border border-gray-200 px-4 py-2">{user.phoneNumber}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="3"
                  className="border border-gray-200 px-4 py-2 text-center text-gray-500"
                >
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
