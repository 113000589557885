import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { app } from '../config/firebase'; // Adjust path based on your project

const Management = () => {
  const [adminInfo, setAdminInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    if (user) {
      const db = getDatabase(app);
      const userRef = ref(db, `AdminInfo/${user.uid}`);

      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData && userData.role === 'super-admin') {
          // Load Admin Info
          const adminInfoRef = ref(db, 'AdminInfo');
          onValue(adminInfoRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const formattedData = Object.entries(data).map(([key, value]) => ({
                id: key,
                ...value,
              }));
              setAdminInfo(formattedData);
            }
            setLoading(false);
          });
        } else {
          navigate('/admin-dashboard/not-authorized'); // Redirect if not super-admin
        }
      });
    } else {
      navigate('/login'); // Redirect to login if user is not authenticated
      setLoading(false);
    }
  }, [navigate]);

  const handlePasswordReset = async (email) => {
    const auth = getAuth(app);
    try {
      await sendPasswordResetEmail(auth, email);
      alert(`Password reset link sent to ${email}`);
    } catch (error) {
      setError(`Failed to send password reset email: ${error.message}`);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen text-xl text-blue-600">
        Loading...
      </div>
    );
  }

  const handleEdit = (adminId) => {
    navigate(`/admin-dashboard/edit-admin/${adminId}`);
  };

  return (
    <div className="container mx-auto p-4 space-y-6">
      <h1 className="text-3xl font-semibold text-center text-gray-800 mb-4">
        Admin Information
      </h1>
      {error && (
        <div className="text-red-500 text-center mb-4">
          {error}
        </div>
      )}
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="px-6 py-3 text-left">Email</th>
              <th className="px-6 py-3 text-left">First Name</th>
              <th className="px-6 py-3 text-left">Last Name</th>
              <th className="px-6 py-3 text-left">Nidaan ID</th>
              <th className="px-6 py-3 text-left">Phone Number</th>
              <th className="px-6 py-3 text-left">Role</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-left">Timestamp</th>
              <th className="px-6 py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {adminInfo.map((admin) => (
              <tr
                key={admin.id}
                className="hover:bg-gray-50 transition duration-200"
              >
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.email}
                </td>
                <td
                  className="px-6 py-4 text-sm text-blue-600 font-medium cursor-pointer border-b hover:text-blue-800"
                  onClick={() => handleEdit(admin.id)}
                >
                  {admin.firstName}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.lastName}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.nidaanId}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.phoneNumber}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.role}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.status}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  {admin.timeStamp}
                </td>
                <td className="px-6 py-4 text-sm text-gray-700 border-b">
                  <button
                    className="text-sm text-blue-600 hover:underline"
                    onClick={() => handlePasswordReset(admin.email)}
                  >
                    Reset Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Management;
