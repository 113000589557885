import React, { useState } from "react";
import loginIcon from "../assets/signin.gif";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { auth, database } from "../config/firebase"; // Adjust path if necessary
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, password, confirmPassword, phoneNumber } = formData;

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setFormData({ ...formData, password: "", confirmPassword: "" });
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Generate unique ID
      const nidaanId = "niu" + Math.floor(100000 + Math.random() * 900000);

      // Create timestamp
      const timestamp = new Date().toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      // Store user info in Realtime Database
      await set(ref(database, `UserInfo/${userId}`), {
        firstName,
        lastName,
        phoneNumber,
        email,
        timeStamp: timestamp,
        nidaanId,
      });

      alert("Sign-up successful!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <section id="login">
      <div className="mx-auto container p-4">
        <div className="bg-white p-4 py-5 w-full max-w-sm mx-auto">
          <div className="w-20 h-20 mx-auto">
            <img src={loginIcon} alt="login" />
          </div>
          <form className="pt-6" onSubmit={handleSignUp}>
            <div className="grid gap-3">
              <label>First Name</label>
              <div className="bg-slate-100">
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  className="w-full h-full outline-none bg-transparent"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <label>Last Name</label>
              <div className="bg-slate-100">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Enter last name"
                  className="w-full h-full outline-none bg-transparent"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <label>Email</label>
              <div className="bg-slate-100">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email"
                  className="w-full h-full outline-none bg-transparent"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <label>Phone Number</label>
              <div className="bg-slate-100">
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  className="w-full h-full outline-none bg-transparent"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div>
              <label>Password</label>
              <div className="bg-slate-100 flex">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter password"
                  className="w-full h-full outline-none bg-transparent"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div>
              <label>Confirm Password</label>
              <div className="bg-slate-100 flex">
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="Confirm password"
                  className="w-full h-full outline-none bg-transparent"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            {errorMessage && <p className="text-red-600">{errorMessage}</p>}
            <button
              type="submit"
              className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-4"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
