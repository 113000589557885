import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase'; // Ensure Firebase is properly initialized
import { collection, getDocs } from 'firebase/firestore';

const AyushmanCode = () => {
  const [ayushmanData, setAyushmanData] = useState([]);

  useEffect(() => {
    const fetchAyushmanData = async () => {
      const querySnapshot = await getDocs(collection(db, 'AyushmanCode'));
      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAyushmanData(data);
    };

    fetchAyushmanData();
  }, []);

  return (
    <div>
      <h2>Ayushman Code Data</h2>
      <table className="table-auto w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border-b border-gray-300 px-4 py-2">Ayushman Code</th>
            <th className="border-b border-gray-300 px-4 py-2">Speciality</th>
          </tr>
        </thead>
        <tbody>
          {ayushmanData.length > 0 ? (
            ayushmanData.map((data) => (
              <tr key={data.id}>
                <td className="border-b border-gray-200 px-4 py-2">{data.ayushmanCode}</td>
                <td className="border-b border-gray-200 px-4 py-2">{data.speciality}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="border-b border-gray-200 px-4 py-2 text-center">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AyushmanCode;
