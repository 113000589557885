import React from 'react'

const AnalyticalHome = () => {
  return (
    <div>
      Analytical Home
    </div>
  )
}

export default AnalyticalHome
