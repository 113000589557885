import React, { useState } from 'react';
import Logo from './Logo';
import { GrSearch } from 'react-icons/gr';
import { FaRegCircleUser, FaCartShopping } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase'; // Ensure the correct path
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';

const Header = () => {
  const navigate = useNavigate();
  const user = auth.currentUser; // Get the currently signed-in user

  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSymptom, setSelectedSymptom] = useState('');

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast.success('Successfully signed out');
      navigate('/login');
    } catch (err) {
      toast.error('Error signing out. Please try again.');
      console.error('Error signing out:', err);
    }
  };

  const handleSearch = async () => {
    try {
      const symptomsCollection = collection(db, 'Symptoms');
      const snapshot = await getDocs(symptomsCollection);
      const query = selectedSymptom.toLowerCase();
      const symptoms = snapshot.docs
        .map(doc => doc.data().symptomname)
        .filter(symptom => symptom.toLowerCase().includes(query));
      setSearchResults(symptoms);
      setShowDropdown(true);
    } catch (error) {
      console.error('Error fetching symptoms:', error);
      toast.error('Failed to fetch symptoms. Please try again.');
    }
  };

  const handleSelectSymptom = (symptom) => {
    setSelectedSymptom(symptom);
    setShowDropdown(false);
  };

  return (
    <header className='h-16 shadow-md bg-white'>
      <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        <div>
          <Link to='/'>
            <Logo w={90} h={60} />
          </Link>
        </div>

        <div className='hidden lg:flex items-center w-full justify-between max-w-sm border rounded-full focus-within:shadow-md pl-2 relative'>
          <input
            type='text'
            placeholder='Search symptoms here ....'
            className='w-full outline-none'
            value={selectedSymptom}
            onChange={(e) => setSelectedSymptom(e.target.value)}
            onKeyUp={handleSearch}
          />
          <div
            className='text-lg min-w-[50px] h-8 bg-red-600 flex items-center justify-center rounded-r-full cursor-pointer'
            onClick={handleSearch}
          >
            <GrSearch />
          </div>
          {showDropdown && searchResults.length > 0 && (
            <div className='absolute top-12 left-0 w-full bg-white border rounded shadow-md z-10 max-h-40 overflow-y-auto'>
              <ul>
                {searchResults.map((symptom, index) => (
                  <li
                    key={index}
                    className='p-2 hover:bg-gray-100 cursor-pointer'
                    onClick={() => handleSelectSymptom(symptom)}
                  >
                    {symptom}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className='flex items-center gap-7'>
          <div className='text-3xl cursor-pointer'>
            <FaRegCircleUser />
          </div>

          <div className='text-3xl cursor-pointer relative'>
            <span>
              <FaCartShopping />
            </span>
            <div className='bg-red-500 text-white w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-3'>
              <p className='text-sm'>0</p>
            </div>
          </div>

          <div>
            {user ? (
              <button
                onClick={handleSignOut}
                className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-500'
              >
                Sign Out
              </button>
            ) : (
              <Link
                to='/login'
                className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-500'
              >
                Login
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
