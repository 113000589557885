import React, { useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const HealthCard = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const db = getFirestore(); // Initialize Firestore

  const handleSearch = async () => {
    setLoading(true);
    setSearchResults([]);
    try {
      const q = query(
        collection(db, 'NidaanHealthCard'),
        where('hcCode', '==', searchInput)
      );
      const mobileQuery = query(
        collection(db, 'NidaanHealthCard'),
        where('mobile', '==', searchInput)
      );
      const nameQuery = query(
        collection(db, 'NidaanHealthCard'),
        where('firstName', '==', searchInput)
      );
      const [hcCodeSnapshot, mobileSnapshot, nameSnapshot] = await Promise.all([
        getDocs(q),
        getDocs(mobileQuery),
        getDocs(nameQuery),
      ]);

      const results = [
        ...hcCodeSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...mobileSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...nameSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching for health cards:', error);
    }
    setLoading(false);
  };

  return (
    <div className="bg-gray-50 py-10">
      {/* Search Section */}
      <div className="bg-white shadow-md rounded-lg p-6 max-w-5xl mx-auto mb-10">
        <h3 className="text-2xl font-bold text-gray-800 text-center mb-6">
          Search Health Card
        </h3>
        <div className="flex items-center gap-4">
          <input
            type="text"
            placeholder="Enter hcCode or mobile"
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button
            className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition"
            onClick={handleSearch}
            disabled={loading || !searchInput.trim()}
          >
            {loading ? 'Searching...' : 'Search'}
          </button>
        </div>

        {/* Search Results */}
        {searchResults.length > 0 ? (
          <div className="mt-6">
            <h4 className="text-xl font-bold text-gray-800 mb-4">Results:</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {searchResults.map((result) => (
    <div
      key={result.id}
      className="p-4 border rounded-lg shadow-md bg-white relative"
    >
      <h5 className="text-lg font-bold text-purple-600 mb-2">
        Nidaan Health Card
      </h5>
      <ul className="text-gray-700 space-y-1">
        <li><strong>मुखिया का नाम:</strong> {result.firstName || 'N/A'}</li>
        <li><strong>पता:</strong> {result.address || 'N/A'}</li>
        <li>
          <strong>मो:</strong> {result.mobile ? result.mobile.slice(0, -5) + 'XXXXX' : 'N/A'}
        </li>
        <li><strong>Health Card Code:</strong> {result.hcCode || 'N/A'}</li>
      </ul>
    </div>
  ))}
</div>

          </div>
        ) : !loading && searchInput.trim() ? (
          <p className="mt-6 text-gray-600">No results found.</p>
        ) : null}
      </div>

      {/* Existing UI components */}
      {/* Pricing Section */}
      <div className="bg-white shadow-md rounded-lg p-6 max-w-5xl mx-auto mb-10">
        <h3 className="text-2xl font-bold text-gray-800 text-center mb-6">
          Nidaan Health Card
        </h3>

        <div className="flex flex-col md:flex-row justify-center items-center gap-6">
          <div className="bg-purple-50 border-2 border-purple-400 rounded-lg p-6 text-center max-w-md">
            <h4 className="text-lg font-bold text-purple-600 mb-4">
              Benifits 
            </h4>
            <ul className="text-gray-700 space-y-2 text-left">
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                Cardholders get discount up to 70% on consultation fees .
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                The card is accepted by all our partner hospitals.
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                A discount of 10% to 15% will be given on all operations / Surgery.
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                Free health checkups (e.g., BP, sugar, weight, etc.) for cardholders.
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                Free consultations and special discounts for soldiers, police personnel, and differently-abled patients.
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                Free health camps will be organized in your village or area by the hospitals.
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                A discount of 10% to 15% will be provided on all tests.
              </li>
              <li className="flex items-start">
                <span className="text-green-500 font-bold text-xl mr-2">✓</span>
                Ambulance services are available.
              </li>
            </ul>
            <div className="mt-6">
              <p className="text-gray-800 text-2xl font-bold mb-2">₹199</p>
              
              <button className="mt-4 px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition">
                Buy Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthCard;
