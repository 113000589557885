import './App.css';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import AdminHeader from './admin-area/admincomponents/AdminHeader';
import AnalyticalHeader from './analytical/analyticalcomponent/AnalyticalHeader'; // Import AnalyticalHeader
import Menu from './components/Menu';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();

  // Check current path
  const isAdminLogin = location.pathname === '/admin-login'; 
  const isAdminDashboard = location.pathname.startsWith('/admin');
  const isAnalyticalHome = location.pathname === '/analytical-home'; 

  return (
    <>
      {/* Conditionally render headers */}
      {!isAdminLogin &&
        (isAnalyticalHome
          ? <AnalyticalHeader />
          : isAdminDashboard
          ? <AdminHeader />
          : <Header />
        )}

      {/* Conditionally render Menu */}
      {!isAdminDashboard && !isAnalyticalHome && <Menu />}

      <main className='min-h-[calc(100vh-100px)]'>
        <Outlet />
      </main>

      {/* Conditionally render Footer */}
      {!isAdminDashboard && !isAnalyticalHome && <Footer />}

      {/* ToastContainer for notifications */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
