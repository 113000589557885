import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';

const OldHealthCard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const healthCardRef = ref(db, 'Health Card');
    onValue(healthCardRef, (snapshot) => {
      const cards = [];
      snapshot.forEach((childSnapshot) => {
        cards.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setData(cards);
    });
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold text-center mb-4">Health Cards</h1>
      <div className="overflow-x-auto">
        <table className="table-auto border-collapse border border-gray-300 w-full text-sm">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2">Name</th>
              <th className="border border-gray-300 px-4 py-2">Address</th>
              <th className="border border-gray-300 px-4 py-2">Mobile</th>
              <th className="border border-gray-300 px-4 py-2">Email</th>
              <th className="border border-gray-300 px-4 py-2">Members</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item) => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="border border-gray-300 px-4 py-2">{item.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.address}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.mobile}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.email || 'N/A'}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {[item.member1, item.member2, item.member3, item.member4]
                      .filter(Boolean)
                      .join(', ')}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  className="border border-gray-300 px-4 py-2 text-center text-gray-500"
                >
                  No data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OldHealthCard;
