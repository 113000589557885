import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase'; // Import your Firebase configuration
import { collection, getDocs } from 'firebase/firestore';
import ProgressBar from '../components/ProgressBar'; // Import the ProgressBar component

const HealthCard = () => {
  const [healthCards, setHealthCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]); // State for filtered data
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0); // State for tracking progress
  const [searchQuery, setSearchQuery] = useState(''); // State for search input

  useEffect(() => {
    const fetchHealthCards = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'NidaanHealthCard'));
        const totalCards = querySnapshot.docs.length; // Total number of cards
        let cards = [];

        // Loop through each document to fetch data and update progress
        for (let i = 0; i < totalCards; i++) {
          const doc = querySnapshot.docs[i];
          cards.push({
            id: doc.id,
            ...doc.data(),
          });

          // Update the progress
          setProgress(Math.floor(((i + 1) / totalCards) * 100));

          // Optional delay for progress visualization
          await new Promise((resolve) => setTimeout(resolve, 50));
        }

        setHealthCards(cards);
        setFilteredCards(cards); // Initialize filtered data with all cards
      } catch (error) {
        console.error('Error fetching health cards:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHealthCards();
  }, []);

  // Filter data based on the search query
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = healthCards.filter((card) =>
      card.firstName?.toLowerCase().includes(lowerCaseQuery) ||
      card.lastName?.toLowerCase().includes(lowerCaseQuery) ||
      card.hcCode?.toLowerCase().includes(lowerCaseQuery) ||
      card.nidaanId?.toLowerCase().includes(lowerCaseQuery) ||
      card.mobile?.toString().includes(lowerCaseQuery)
    );
    setFilteredCards(filtered);
  }, [searchQuery, healthCards]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Health Card Data</h1>

      {/* Search bar */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Name, HC Code, or Mobile Number"
          className="w-full border border-gray-300 rounded p-2"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Show progress bar while loading */}
      {loading ? (
        <div className="mb-4">
          <ProgressBar progress={progress} height="10px" color="#4caf50" />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">Document ID</th>
                
                <th className="border border-gray-300 px-4 py-2">First Name</th>
                <th className="border border-gray-300 px-4 py-2">Last Name</th>
                <th className="border border-gray-300 px-4 py-2">Mobile</th>
                <th className="border border-gray-300 px-4 py-2">Partner Id</th>
                <th className="border border-gray-300 px-4 py-2">Payment Method</th>
                <th className="border border-gray-300 px-4 py-2">HC Code</th>
                <th className="border border-gray-300 px-4 py-2">Members</th>
                <th className="border border-gray-300 px-4 py-2">Amount</th>
                <th className="border border-gray-300 px-4 py-2">Email</th>                
                <th className="border border-gray-300 px-4 py-2">Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {filteredCards.length > 0 ? (
                filteredCards.map((card) => (
                  <tr key={card.id} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">{card.id}</td>
                    
                    <td className="border border-gray-300 px-4 py-2">{card.firstName}</td>
                    <td className="border border-gray-300 px-4 py-2">{card.lastName}</td>
                    <td className="border border-gray-300 px-4 py-2">{card.mobile}</td>
                    <td className="border border-gray-300 px-4 py-2">{card.nidaanId}</td>
                    <td className="border border-gray-300 px-4 py-2">{card.paymentMethod}</td>
                    <td className="border border-gray-300 px-4 py-2">{card.hcCode}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      {[card.member1, card.member2, card.member3, card.member4]
                        .filter(Boolean)
                        .join(', ')}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">{card.amount}</td>
                    <td className="border border-gray-300 px-4 py-2">{card.email}</td>                   
                    <td className="border border-gray-300 px-4 py-2">{card.timestamp}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    className="text-center border border-gray-300 px-4 py-2"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HealthCard;
