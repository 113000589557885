import React from 'react'

const DoctorsAdmin = () => {
  return (
    <div>
      Doctor Admin
    </div>
  )
}

export default DoctorsAdmin
