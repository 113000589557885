import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import pmjay from '../assets/pmjay.jpg';

const HospitalDetails = () => {
    const { hospitalId } = useParams(); // Get the hospitalId from the route
    const [hospital, setHospital] = useState(null);

    // Extract latitude and longitude from the location field
    const getLatitudeLongitude = (locationString) => {
        if (!locationString) return { latitude: null, longitude: null };

        const parts = locationString.split(',');
        if (parts.length >= 2) {
            const latitude = parseFloat(parts[0].trim());
            const longitude = parseFloat(parts[1].trim());

            if (!isNaN(latitude) && !isNaN(longitude)) {
                return { latitude, longitude };
            }
        }
        return { latitude: null, longitude: null };
    };

    useEffect(() => {
        const fetchHospitalDetails = async () => {
            try {
                const docRef = doc(db, 'Hospital', hospitalId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setHospital(docSnap.data());
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching hospital details:', error);
            }
        };

        fetchHospitalDetails();
    }, [hospitalId]);

    const { latitude, longitude } = hospital?.location
        ? getLatitudeLongitude(hospital.location)
        : { latitude: null, longitude: null };

    return (
        <div className="p-4">
            {hospital ? (
                <>
                    {/* Wrapper for image and logo */}
                    <div className="relative">
                        {/* Display hospital image */}
                        {hospital.hospitalimage && (
                            <img
                                src={hospital.hospitalimage}
                                alt="Hospital"
                                className="w-full h-64 mb-4 rounded-lg shadow-lg"
                            />
                        )}
                        {/* Display hospital logo */}
                        {hospital.hospitallogo && (
                            <img
                                src={hospital.hospitallogo}
                                alt={`${hospital.hospitalname} Logo`}
                                className="absolute bottom-1 left-7 w-20 h-20 rounded-full border-2 border-white shadow-lg"
                            />
                        )}

                        {/* Conditionally render pmjay image if ayushmanCode is available */}
                        {hospital.ayushmanCode && (
                            <img
                                src={pmjay}
                                alt="PMJAY"
                                className="absolute px-4 top-8 w-50 h-20"
                            />
                        )}
                    </div>

                    {/* Hospital details */}
                    <h2 className="text-2xl font-bold mb-4">{hospital.hospitalname}</h2>
                    <p>{hospital.hospitaladdress}</p>
                    <p>{hospital.hospitalcity}</p>
                    <p>{hospital.hospitalservices}</p>
                    <p><b>Ayushman available in this hospital for </b></p>
                    <p className="text-2xl text-balance">{hospital.ayushmanCode}</p>

                    {/* Google Map */}
                    <div className="mt-6">
                        {latitude && longitude ? (
                            <iframe
                                title="Hospital Location"
                                src={`https://www.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`}
                                width="100%"
                                height="400"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        ) : (
                            <p className="text-red-500">Location not available.</p>
                        )}
                    </div>
                </>
            ) : (
                <p>Loading hospital details...</p>
            )}
        </div>
    );
};

export default HospitalDetails;
