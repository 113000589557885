import React, { useState } from 'react';

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="shadow-md bg-amber-200">
      {/* Navbar for larger screens */}
      <div className="container mx-auto px-4 h-12 flex items-center justify-between">
        <div className="text-lg font-bold"></div>
        {/* Hamburger menu icon for mobile */}
        <div
          className="block md:hidden cursor-pointer"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </div>

        {/* Menu for larger screens */}
        <div className="hidden md:flex gap-4 text-md">
          <div className="hover:scale-110 transition-all cursor-pointer">
            Find Doctor
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Find Hospital
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Video Consult
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Book Test
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Surgeries
          </div>
        </div>
      </div>

      {/* Dropdown menu for mobile */}
      {isMenuOpen && (
        <div className="md:hidden flex flex-col gap-2 bg-amber-300 p-4 text-center">
          <div className="hover:scale-110 transition-all cursor-pointer">
            Find Doctor
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Find Hospital
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Video Consult
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Book Test
          </div>
          <div className="hover:scale-110 transition-all cursor-pointer">
            Surgeries
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
