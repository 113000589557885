import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase'; // Ensure Firebase config is correctly set up
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';

const HospitalAdmin = () => {
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const navigate = useNavigate(); // For navigation to the add hospital page

  useEffect(() => {
    const fetchHospitals = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Hospital'));
        const hospitalList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHospitals(hospitalList);
        setFilteredHospitals(hospitalList);
      } catch (error) {
        console.error('Error fetching hospital data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHospitals();
  }, []);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = hospitals.filter(
      (hospital) =>
        hospital.hospitalname?.toLowerCase().includes(lowercasedQuery) ||
        hospital.hospitalcity?.toLowerCase().includes(lowercasedQuery) ||
        hospital.hospitalservices?.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredHospitals(filtered);
  }, [searchQuery, hospitals]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this hospital?')) {
      try {
        await deleteDoc(doc(db, 'Hospital', id));
        alert('Hospital deleted successfully!');
        setHospitals((prev) => prev.filter((hospital) => hospital.id !== id));
        setFilteredHospitals((prev) =>
          prev.filter((hospital) => hospital.id !== id)
        );
      } catch (error) {
        console.error('Error deleting hospital:', error);
        alert('Failed to delete hospital. Please try again.');
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-start items-center mb-4">

      <h1 className="text-2xl font-bold mb-4">Hospital Administration</h1>

        <button
        onClick={() => navigate('/admin-dashboard/add-hospital')}
        className="ml-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
        Add Hospital
      </button>

      </div>
     

      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search by Name, City, or Services"
          className="p-2 border border-gray-300 rounded w-full max-w-md"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
       
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 px-4 py-2">Document ID</th>
                <th className="border border-gray-300 px-4 py-2">Hospital Name</th>
                <th className="border border-gray-300 px-4 py-2">Address</th>
                <th className="border border-gray-300 px-4 py-2">City</th>
                <th className="border border-gray-300 px-4 py-2">Number</th>
                <th className="border border-gray-300 px-4 py-2">Ayushman</th>
                <th className="border border-gray-300 px-4 py-2">Ayushman Code</th>
                <th className="border border-gray-300 px-4 py-2">Services</th>
                <th className="border border-gray-300 px-4 py-2">Logo</th>
                <th className="border border-gray-300 px-4 py-2">Image</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredHospitals.length > 0 ? (
                filteredHospitals.map((hospital) => (
                  <tr key={hospital.id} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">{hospital.id}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      <Link
                        to={`/admin-dashboard/edit-hospital/${hospital.id}`}
                        className="text-blue-500 hover:underline"
                      >
                        {hospital.hospitalname}
                      </Link>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">{hospital.hospitaladdress}</td>
                    <td className="border border-gray-300 px-4 py-2">{hospital.hospitalcity}</td>
                    <td className="border border-gray-300 px-4 py-2">{hospital.hospitalnumber}</td>
                    <td className="border border-gray-300 px-4 py-2">{hospital.hospitalayushman}</td>
                    <td className="border border-gray-300 px-4 py-2">{hospital.ayushmanCode}</td>
                    <td className="border border-gray-300 px-4 py-2">{hospital.hospitalservices}</td>
                    <td className="border border-gray-300 px-4 py-2">
                      <img
                        src={hospital.hospitallogo}
                        alt={`${hospital.hospitalname} logo`}
                        className="w-16 h-16 object-contain"
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <img
                        src={hospital.hospitalimage}
                        alt={`${hospital.hospitalname}`}
                        className="w-32 h-32 object-cover"
                      />
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        onClick={() => handleDelete(hospital.id)}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center border border-gray-300 px-4 py-2">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default HospitalAdmin;
