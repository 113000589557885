import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';
import { toast } from 'react-toastify';
import { auth } from '../../config/firebase'; // Ensure the correct path
import { signOut } from 'firebase/auth';




const AdminHeader = () => {
    const navigate = useNavigate();
    const user = auth.currentUser; // Get the currently signed-in user

    const handleSignOut = async () => {
        try {
          await signOut(auth);
          toast.success('Successfully signed out');
          navigate('/');
        } catch (err) {
          toast.error('Error signing out. Please try again.');
          console.error('Error signing out:', err);
        }
      };   



  return (
    <header className='h-16 shadow-md bg-white'>
         <div className='h-full container mx-auto flex items-center px-4 justify-between'>
        <div>
          <Link to='/admin-dashboard'>
            <Logo w={90} h={60} />
          </Link>
        </div>

         <div>
                    {user ? (
                      <button
                        onClick={handleSignOut}
                        className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-500'
                      >
                        Sign Out
                      </button>
                    ) : (
                      <Link
                        to='/login'
                        className='px-3 py-1 rounded-full text-white bg-red-600 hover:bg-red-500'
                      >
                        Login
                      </Link>
                    )}
                  </div>

        </div>
    </header>
  )
}

export default AdminHeader
