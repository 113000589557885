import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../config/firebase'; // Ensure you have Firebase configured
import { collection, getDocs } from 'firebase/firestore';
import { getDatabase, ref, onValue } from "firebase/database"; // Import Firebase Realtime Database methods

const AdminHome = () => {
  const [healthCardCount, setHealthCardCount] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [hospitalCount, setHospitalCount] = useState(0);
  const [userCount, setUserCount] = useState(0); // State for user count
  const [partnerCount, setPartnerCount] = useState(0); // State for user count

  useEffect(() =>{
    const db = getDatabase(); // Initialize Firebase Database
    const usersRef = ref(db, "UserInfo"); // Reference to UserInfo node
     // Listen for changes in the UserInfo data

     const unsubscribe = onValue(usersRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const userCount = Object.keys(data).length; // Get the number of users
        setUserCount(userCount); // Set the user count
      } else {
        setUserCount(0); // If no data exists, set count to 0
      }
    });
    return () => unsubscribe(); // Cleanup listener on component unmount
  }, []);

  useEffect(() =>{
    const db = getDatabase(); // Initialize Firebase Database
    const partnerRef = ref(db, "PartnerInfo"); // Reference to UserInfo node
     // Listen for changes in the UserInfo data

     const unsubscribe = onValue(partnerRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const partnerCount = Object.keys(data).length; // Get the number of users
        setPartnerCount(partnerCount); // Set the user count
      } else {
        setPartnerCount(0); // If no data exists, set count to 0
      }
    });
    return () => unsubscribe(); // Cleanup listener on component unmount
  }, []);


  useEffect(() => {
    const fetchHealthCardCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'NidaanHealthCard'));
        setHealthCardCount(querySnapshot.size); // Set the count of documents
      } catch (error) {
        console.error('Error fetching health card count:', error);
      }
    };

    fetchHealthCardCount();
  }, []);

 

  useEffect(() => {
    const fetchPatientCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'NidaanPatient'));
        setPatientCount(querySnapshot.size); // Set the count of documents
      } catch (error) {
        console.error('Error fetching patient count:', error);
      }
    };

    fetchPatientCount();
  }, []);

  useEffect(() => {
    const fetchHospitalCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Hospital'));
        setHospitalCount(querySnapshot.size); // Set the count of documents
      } catch (error) {
        console.error('Error fetching health card count:', error);
      }
    };

    fetchHospitalCount();
  }, []);
    

  return (

    <header>

    <div className="p-6">
      
      {/* Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        
        {/* Card 1: User */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Users<span className="text-md text-gray-500">({userCount})</span>            
            </h2>
          <p className="text-gray-600">Manage Users profiles and details..</p>
          <Link
            to="users"
            className="mt-4 inline-block bg-green-500 text-white py-2 px-4 rounded">
            User
          </Link>
        </div>

        {/* Card 2: Hospital */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">
            Hospital<span className="text-md text-gray-500">({hospitalCount})</span>
            </h2>
          <p className="text-gray-600">Manage Hospital profiles and details..</p>
          <Link
            to="hospital-admin"
            className="mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
            Hospital
          </Link>
        </div>

        {/* Card 3: Doctor */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Doctor</h2>
          <p className="text-gray-600">Manage doctor profiles and details.</p>
          <Link
            to="doctors-admin"
            className="mt-4 inline-block bg-orange-800 text-white py-2 px-4 rounded">
            Doctors
          </Link>
        </div>

         {/* Card 4: Health Card */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">
            Health Card <span className="text-md text-gray-500">({healthCardCount})</span>
          </h2>
          <p className="text-gray-600">Manage Health Card Data and Profile.</p>
          <Link
            to="health-card-admin"
            className="mt-4 inline-block bg-red-400 text-white py-2 px-4 rounded">
            Health Card
          </Link>
        </div>

        {/* Card 5: Patient */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">
            Patient<span className="text-md text-gray-500">({patientCount})</span>
            </h2>
          <p className="text-gray-600">Manage patient Data and Profile.</p>
          <Link
            to="patient-admin"
            className="mt-4 inline-block bg-orange-500 text-white py-2 px-4 rounded">
            Patient
          </Link>
        </div>

        {/* Card 6: Partner */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Partner<span className="text-md text-gray-500">({partnerCount})</span>

          </h2>
          <p className="text-gray-600">Manage partner Data and Profile.</p>
          <Link
            to="partner"
            className="mt-4 inline-block bg-zinc-500 text-white py-2 px-4 rounded">
            Partner
          </Link>
        </div>

        {/* Card 6: Doctor Database */}
        <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">
            Doctor Database <span className="text-md text-gray-500">0</span>
          </h2>
          <p className="text-gray-600">Manage Doctor Database and Profile.</p>
          <Link
            to="doctors-database"
            className="mt-4 inline-block bg-red-400 text-white py-2 px-4 rounded">
            Doctor Database
          </Link>
        </div>

         {/* Card 6: calander */}
         <div className="bg-white p-4 shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-2">
            calander <span className="text-md text-gray-500">0</span>
          </h2>
          <p className="text-gray-600">Manage Doctor Database and Profile.</p>
          <Link
            to="hc-calander"
            className="mt-4 inline-block bg-red-400 text-white py-2 px-4 rounded">
            Calander
          </Link>
        </div>

      

      </div>



      </div>

      </header>

  )
}

export default AdminHome
