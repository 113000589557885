import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase'; // Import Firestore db
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { getStorage, ref, deleteObject } from 'firebase/storage'; // Firebase Storage


const PatientAdmin = () => {
  const [patients, setPatients] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchPatients = async () => {
      try {
        // Reference to the "NidaanPatient" collection
        const querySnapshot = await getDocs(collection(db, 'NidaanPatient'));
        const patientData = [];

        // Loop through each document in the collection and extract data
        querySnapshot.forEach((doc) => {
          patientData.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        // Set the patient data to state
        setPatients(patientData);
        setFilteredPatients(patientData); // Initially, show all patients
      } catch (error) {
        console.error('Error fetching patients: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPatients();
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredPatients(patients); // Show all patients if no search term
    } else {
      // Filter patients based on the search term (case insensitive)
      const filtered = patients.filter((patient) => {
        return (
          (patient.name && patient.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (patient.number && patient.number.includes(searchTerm)) ||
          (patient.refBy && patient.refBy.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (patient.partnerId && patient.partnerId.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (patient.hospital && patient.hospital.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      });
      setFilteredPatients(filtered);
    }
  }, [searchTerm, patients]);

  const handleDelete = async (id, storagePath) => {
    if (window.confirm('Are you sure you want to delete this patient?')) {
      try {
        const storage = getStorage();
        const fileRef = ref(storage, storagePath);

        // Delete file from Firebase Storage
        if (storagePath) {
          await deleteObject(fileRef);
          console.log(`File at ${storagePath} deleted successfully.`);
        }

        // Delete Firestore document
        await deleteDoc(doc(db, 'NidaanPatient', id));

        // Update state
        const updatedPatients = patients.filter((patient) => patient.id !== id);
        setPatients(updatedPatients);
        setFilteredPatients(updatedPatients);
      } catch (error) {
        console.error('Error deleting patient or file: ', error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by Name, Number, RefBy, or Hospital"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 border rounded"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr>
              <th className="px-4 py-2 border">ID</th>
              <th className="px-4 py-2 border">Name</th>
              <th className="px-4 py-2 border">Number</th>
              <th className="px-4 py-2 border">RefBy</th>
              <th className="px-4 py-2 border">RefNumber</th>
              <th className="px-4 py-2 border">Aadhar</th>
              <th className="px-4 py-2 border">Ayushman</th>
              <th className="px-4 py-2 border">NidaanPtId</th>
              <th className="px-4 py-2 border">Samagra</th>
              <th className="px-4 py-2 border">Admit</th>
              <th className="px-4 py-2 border">Discharge</th>
              <th className="px-4 py-2 border">Hospital</th>
              <th className="px-4 py-2 border">PartnerId</th>
              <th className="px-4 py-2 border">Timestamp</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.map((patient) => (
              <tr key={patient.id}>
                <td className="px-4 py-2 border"><Link to={`/admin-dashboard/edit-patient/${patient.id}`} className="text-blue-500 hover:underline">
                  {patient.id}
                  </Link></td>
                <td className="px-4 py-2 border"><Link to={`/admin-dashboard/edit-patient/${patient.id}`} className="text-blue-500 hover:underline">
                  {patient.name}
                  </Link></td>
                  <td className="px-4 py-2 border">{patient.number}</td>
                <td className="px-4 py-2 border">{patient.refBy}</td>
                <td className="px-4 py-2 border">{patient.refNumber}</td>
                <td className="px-4 py-2 border">{patient.aadhar ? 'Yes' : 'No'}</td>
                <td className="px-4 py-2 border">{patient.ayushman ? 'Yes' : 'No'}</td>
                <td className="px-4 py-2 border">{patient.NidaanPtId}</td>
                <td className="px-4 py-2 border">{patient.samagra ? 'Yes' : 'No'}</td>
                <td className="px-4 py-2 border">{patient.admit ? 'Yes' : 'No'}</td>
                <td className="px-4 py-2 border">{patient.discharge ? 'Yes' : 'No'}</td>
                <td className="px-4 py-2 border">{patient.hospital}</td>
                <td className="px-4 py-2 border">{patient.partnerId}</td>
                <td className="px-4 py-2 border">{patient.timestamp}</td>
                <td className="px-4 py-2 border">
                <button
                    onClick={() => handleDelete(patient.id, patient.storagePath)} // Pass `storagePath`
                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientAdmin;
