import React, { useState } from 'react';
import loginIcon from '../assets/signin.gif';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../config/firebase';
import { getDatabase, ref, get } from 'firebase/database';

const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const db = getDatabase();
      const userRef = ref(db, `AdminInfo/${user.uid}`);
      const snapshot = await get(userRef);

      if (snapshot.exists()) {
        const userData = snapshot.val();
        const role = userData.role;

        // Display the correct message with user's role or name
        setMessage(`Hello ${userData.firstName || user.uid}, welcome ${role}`);
        
        if (role === 'Manager' || role === 'Admin' || role === 'super-admin') {
          navigate('/admin-dashboard');
        } else {
          setError('You do not have the required permissions to access this page.');
        }
      } else {
        setError('User not found in the database.');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <section id="login">
      <div className="mx-auto container p-4">
        <div className="bg-white p-4 py-5 w-full max-w-sm mx-auto">
          <div className="w-20 h-20 mx-auto">
            <img src={loginIcon} alt="login" />
          </div>

          <form className="pt-6" onSubmit={handleLogin}>
            <div className="grid">
              <label>Email :</label>
              <div className="bg-slate-100">
                <input
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full h-full outline-none bg-transparent"
                />
              </div>
            </div>

            <div>
              <label>Password :</label>
              <div className="bg-slate-100 flex">
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full h-full outline-none bg-transparent"
                />
                <div
                  className="cursor-pointer text-lg"
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
              <Link to="/forgot-password" className="block w-fit ml-auto hover:underline hover:text-red-600">
                Forgot Password
              </Link>
            </div>

            {error && <p className="text-red-600 text-center mt-2">{error}</p>}
            {message && <p className="text-green-600 text-center mt-2">{message}</p>} {/* Display success message */}

            <button className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 w-full max-w-[150px] rounded-full hover:scale-110 transition-all mx-auto block mt-4">
              Login
            </button>
          </form>
          <p className="my-4">
            Don't have an account?{' '}
            <Link to="/admin-sign-up" className="hover:text-red-700 hover:underline">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
